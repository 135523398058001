import React from 'react'
import { useStaticQuery } from 'gatsby'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'
import Showcase from 'components/elements/Showcase'

// Third Party
import styled from 'styled-components'

const StyledShowCases = styled.div``

const ShowCases = ({ className }) => {
  const {
    showcases: {
      nodes: showcases
    }
  } = useStaticQuery(graphql`{
    showcases: allWordpressWpShowcase {
      nodes {
        ...ShowcasePreviewFrag
      }
    }
  }`)

  return (
    <StyledShowCases className={`container ${className ? `${className}` : ``}`}>
      <div className="row">
        {showcases.map(s => (
          <div className="col-lg-6 pb-lg-5 pb-3">
            <Showcase showcase={s} key={s.wordpress_id} />
          </div>
        ))}
      </div>
      {/* <div className="d-flex justify-content-center my-5 pb-5">
        <ButtonDefault type="outline">Laat meer producten zien</ButtonDefault>
      </div> */}
    </StyledShowCases>
  )
}

export default ShowCases