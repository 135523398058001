/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroAlt from 'components/elements/HeroAlt'
import ShowCases from 'components/elements/ShowCases'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'

const ShowcasesContainer = styled.div`
  margin-top: -200px;

  @media screen and (max-width: 991px) {
    margin-top: -100px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activePage={path}>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <HeroAlt fields={acf.banner} />

      <ShowcasesContainer>
        <ShowCases className="pb-lg-5 pb-3" />
      </ShowcasesContainer>
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ShowcasePageFrag
    }
  }
`

export default PageTemplate