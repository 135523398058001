import React from 'react'

// Third Party
import styled, { css } from 'styled-components'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Images
import Icon from 'img/icon-right.svg'

const Container = styled(ImageBackground)`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.text.main};
  position: relative;
  height: 400px;
  width: 470px;
  margin: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px auto;
  }
`

const Title = styled.div`
  font-family: ${props => props.theme.font.family.main};
  font-weight: ${props => props.theme.font.weight.l};
  font-size: ${props => props.theme.font.size.xm};
  ${props => props.black ? css`
    color: ${props => props.theme.color.text.light};
    background: ${props => props.theme.color.face.dark};
  ` : css`
    color: ${props => props.theme.color.text.secondary};
    background: ${props => props.theme.color.face.light};
  `}
  padding: 30px 40px 30px 50px;
`

const Date = styled.div`
  font-family: ${props => props.theme.font.family.main};
  font-size: ${props => props.theme.font.size.m};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.main};
  padding: 12px 24px;
  background: white;
  width: fit-content;
`


const Showcase = ({ showcase, black }) => {
  return (
    <Container blackFade={black} src={showcase.acf.preview.image} style={{ backgroundSize: 'cover' }}>
      {/* {showcase.acf.preview.date && <Date>{showcase.acf.preview.date}</Date>} */}
      <CustomLink to={showcase.path} className="d-flex flex-column justify-content-end w-100 h-100">
        <Title black={black} className="d-flex justify-content-between">
          <span className="mr-2">{<ParseContent content={showcase.title} />}</span>
          <img src={Icon} alt="arrow" />
        </Title>
      </CustomLink>
    </Container>
  )
}

export default Showcase