/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'


const Hero = styled(ImageBackground)`
  color: ${(props) => props.theme.color.text.main};
  min-height: 500px;

  &:before {
    background-position: bottom !important;
    background-size: cover !important;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.xxxl};
    line-height: ${(props) => props.theme.font.size.xxxl};
    font-family: ${(props) => props.theme.font.family.secondary};
    text-transform: uppercase;

    @media screen and (max-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl};
      line-height: ${(props) => props.theme.font.size.xxl};
    }
  }

  p {
    font-size: ${props => props.theme.font.size.xm};
    line-height: ${props => props.theme.font.size.xl};
    font-family: ${props => props.theme.font.family.main};
  }
`

const RedLine = styled.div`
  width: calc(50% + 416px);
  height: 2px;
  border-bottom: 5px solid ${(props) => props.theme.color.face.main};
  @media screen and (max-width: 992px) {
    width: calc(50% + 142px);
  }
`

const HeroAlt = ({ fields }) => {
  return (
    <Hero
      src={fields.image}
      style={{ backgroundSize: '100% 100%', backgroundPosition: 'top' }}
      className="pt-5"
      aboveFold
    >
      <div className="container d-flex flex-wrap pt-lg-5 pt-3">
        <div className="col-lg-5">
          <ParseContent content={`<h2>${fields.title} </h2>`} />
        </div>
        <div className="col-lg-6 my-2">
          <ParseContent content={`${fields.description}`} />
        </div>
      </div>
      <RedLine />
    </Hero>
  )
}

export default HeroAlt